import {getNBAStoreReportService, getNBATaskReportService} from '../services/storeReportService'
import {GetReportResponse, GetTaskReportResponse} from '../types/StoreReport'
import {getCountries} from '@/helpers/envConfig'
import {store} from '@/store'

export type GetReportRequestSchema = {
  pageNumber?: number
  pageSize?: number
  sort?: string
  query?: string
  nbaId?: string | undefined
}

export const DEFAULT_SORT = 'name,asc'

export const DEFAULT_PAGE_SIZE = 10

export async function getStoreReportUseCase(
  params?: GetReportRequestSchema,
  signal?: AbortSignal
): Promise<GetReportResponse> {
  const {pageNumber = 0, pageSize = DEFAULT_PAGE_SIZE, sort = DEFAULT_SORT, query = '', nbaId} = params || {}
  const optionalParams = nbaId ? {nbaId} : {}
  const countryCode = store.getState().common.country.code || getCountries()[0]
  return getNBAStoreReportService({pageNumber, pageSize, sort, query, countryCode, ...optionalParams}, signal)
}

export async function getTaskReportUseCase(
  params?: GetReportRequestSchema,
  signal?: AbortSignal
): Promise<GetTaskReportResponse> {
  const {pageNumber = 0, pageSize = DEFAULT_PAGE_SIZE, sort = DEFAULT_SORT, query = '', nbaId} = params || {}
  const optionalParams = nbaId ? {nbaId} : {}
  const countryCode = store.getState().common.country.code || getCountries()[0]
  return getNBATaskReportService({pageNumber, pageSize, sort, query, countryCode, ...optionalParams}, signal)
}
