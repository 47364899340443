import {ENDPOINTS} from '@/config/constants'
import api, {HTTPMethod} from '../helpers/api'
import {
  GetStoreReportRequest,
  GetStoreReportRequestSchema,
  GetReportResponse,
  GetStoreReportResponseSchema,
  GetTaskReportRequest,
  GetTaskReportResponse,
  GetTaskReportResponseSchema,
  GetTaskReportRequestSchema,
} from '../types/StoreReport'

export const getNBAStoreReportService = (params: GetStoreReportRequest, signal?: AbortSignal) => {
  return api<GetStoreReportRequest, GetReportResponse>({
    method: HTTPMethod.GET,
    path: ENDPOINTS.NBA_STORE_REPORT,
    requestSchema: GetStoreReportRequestSchema,
    responseSchema: GetStoreReportResponseSchema,
    signal,
  })(params)
}

export const getNBATaskReportService = (params: GetTaskReportRequest, signal?: AbortSignal) => {
  return api<GetTaskReportRequest, GetTaskReportResponse>({
    method: HTTPMethod.GET,
    path: ENDPOINTS.NBA_TASK_REPORT,
    requestSchema: GetTaskReportRequestSchema,
    responseSchema: GetTaskReportResponseSchema,
    signal,
  })(params)
}
